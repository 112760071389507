module.exports = [
  {
    source: '/community-guidelines(.html)?',
    destination: '/page/community-guidelines',
    permanent: true,
  },
  { source: '/terms-of-use(.html)?', destination: '/page/terms-of-use', permanent: true },
  {
    source: '/privacy-policy(.html)?',
    destination: '/privacy-notice',
    permanent: true,
  },
  { source: '/privacy(.html)?', destination: '/privacy-notice', permanent: true },
  { source: '/page/privacy-notice', destination: '/privacy-notice', permanent: true },
  {
    source: '/cookie-policy(.html)?',
    destination: '/page/cookie-policy',
    permanent: true,
  },
  {
    source: '/page/about-us',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/page/become-an-impacter',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/page/work-with-us',
    destination: 'https://careers.milkywire.com',
    permanent: true,
    basePath: false,
  },
  {
    source: '/work-with-us',
    destination: 'https://careers.milkywire.com',
    permanent: true,
    basePath: false,
  },
  {
    source: '/beethechange',
    destination: '/bee-the-change',
    permanent: true,
  },
  {
    source: '/climate-transformation-portfolio',
    destination: '/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/climate-transformation-fund',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/nature-transformation-fund',
    destination: '/impact-funds/nature-transformation-fund',
    permanent: true,
  },
  {
    source: '/save-oceans-and-seas',
    destination: '/impact-funds/save-oceans-and-seas',
    permanent: true,
  },
  {
    source: '/impact-funds/save-oceans-and-seas',
    destination: '/impact-funds/oceans-and-seas-fund',
    permanent: true,
  },
  {
    source: '/protect-wildlife',
    destination: '/impact-funds/protect-wildlife',
    permanent: true,
  },
  {
    source: '/impact-funds/protect-wildlife',
    destination: '/impact-funds/wildlife-fund',
    permanent: true,
  },
  {
    source: '/protect-forests-and-plant-trees',
    destination: '/impact-funds/protect-forests-and-plant-trees',
    permanent: true,
  },
  {
    source: '/impact-funds/protect-forests-and-plant-trees',
    destination: '/impact-funds/forests-and-landscapes-fund',
    permanent: true,
  },
  {
    source: '/climate-transformation-portfolio-readmore',
    destination: '/climate-transformation-fund-readmore',
    permanent: true,
  },
  { source: '/categories/:name', destination: '/impact-funds/:name', permanent: true },
  { source: '/causes/:name', destination: '/impact-funds/:name', permanent: true },
  {
    // Redirect app login path to download app page
    source: '/deep-link-login-app',
    destination: '/app',
    permanent: true,
  },
  {
    source: '/invite',
    destination: '/login?step=sign-up-email&returnUrl=%2Fmy-dashboard',
    permanent: true,
  },
  {
    source: '/blog/:name',
    destination: '/articles/:name',
    permanent: true,
  },
  {
    source: '/my-dashboard/one-time-donations',
    destination: '/my-dashboard/transactions',
    permanent: true,
  },
  {
    source: '/greengamejam/:studio',
    destination: '/greengamejam',
    permanent: true,
  },
  {
    source: '/giveone/:page',
    destination: '/klarna/:page',
    permanent: true,
  },
  {
    source: '/impact-funds/fight-climate-change',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/gift',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/give-a-gift',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/wildlifeday',
    destination: '/impact-funds/protect-wildlife',
    permanent: true,
  },
  {
    source: '/klarna/ali-emergency-fund',
    destination: '/klarna/wildlife',
    permanent: true,
  },
  {
    source: '/klarna/ali-emergency-fund1',
    destination: '/klarna/wildlife1',
    permanent: true,
  },
  {
    source: '/heidi',
    destination: '/impact-funds',
    permanent: true,
  },
  {
    source: '/support-environmental-defenders',
    destination: '/impact-funds',
    permanent: true,
  },
  {
    source: '/impact-funds/support-environmental-defenders',
    destination: '/impact-funds',
    permanent: true,
  },
  {
    source: '/ad1-see',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/ad2-think',
    destination: '/impact-funds/protect-wildlife',
    permanent: true,
  },
  {
    source: '/campaign-earthday22',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/klarna/climateinitiative1',
    destination: '/ctf-stripe',
    permanent: true,
  },
  {
    source: '/klarna/climateinitiative',
    destination: '/klarna/ctf',
    permanent: true,
  },
  {
    source: '/ctf',
    destination: '/klarna/ctf',
    permanent: true,
  },
  {
    source: '/trickortree',
    destination: '/contact',
    permanent: true,
  },
  {
    source: '/tomorrowtree',
    destination: '/contact',
    permanent: true,
  },
  {
    source: '/thesolarproject',
    destination: '/contact',
    permanent: true,
  },
  {
    source: '/viking-save-our-forests',
    destination: '/contact',
    permanent: true,
  },
  {
    source: '/impact-funds/save-the-oceans',
    destination: '/impact-funds/save-oceans-and-seas',
    permanent: true,
  },
  {
    source: '/impact-funds/restore-forests-and-landscapes',
    destination: '/impact-funds/protect-forests-and-plant-trees',
    permanent: true,
  },
  {
    source: '/climate-transformation-fund-readmore',
    destination: '/ctf-report-2023',
    permanent: true,
  },
  {
    source: '/products',
    destination: '/platform',
    permanent: true,
  },
  {
    source: '/collaborations-pangaia',
    destination: '/pangaia',
    permanent: true,
  },
  {
    source: '/ad3-do-sv',
    destination: '/impact-funds/protect-wildlife',
    permanent: true,
  },
  {
    source: '/ad2-think-sv',
    destination: '/impact-funds/protect-wildlife',
    permanent: true,
  },
  {
    source: '/ad1-see-sv',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/ad3-do',
    destination: '/impact-funds/protect-wildlife',
    permanent: true,
  },
  {
    source: '/open-call-ctf-2023',
    destination: '/open-call',
    permanent: true,
  },
  {
    source: '/open-call',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/klarna/climateinitiative-readmore',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/blog',
    destination: '/articles',
    permanent: true,
  },
  {
    source: '/buy-gift',
    destination: '/impact-funds/climate-transformation-fund',
    permanent: true,
  },
  {
    source: '/collaborations-klarna',
    destination: '/klarna',
    permanent: true,
  },
  {
    source: '/impacter-faq',
    destination: '/frequently-asked-questions',
    permanent: true,
  },
  {
    source: '/active-impacter-faq',
    destination: '/frequently-asked-questions',
    permanent: true,
  },
  {
    source: '/partnerships',
    destination: '/contact',
    permanent: true,
  },
  {
    source: '/about/quality',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/about/impact',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/becomeanimpacter',
    destination: '/about',
    permanent: true,
  },
  {
    source: '/home',
    destination: '/',
    permanent: true,
  },
];
